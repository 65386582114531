import { Button, Flex, Heading, Image, Stack } from "@chakra-ui/react";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { RiGoogleLine } from "react-icons/ri";

import { getFirebaseAuth } from "@/Firebase";

import logo from "../logo.svg";
import splash from "../splash.jpg";

export const SignIn = () => {
  const auth = getFirebaseAuth();
  const [signInWithGoogle, , , ,] = useSignInWithGoogle(auth);

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={10} w={"full"} maxW={"md"}>
          <Heading fontSize={"4xl"} color="primary.500">
            <Image alt={"Logo"} src={logo} />
          </Heading>
          <Stack spacing={6}>
            <Button
              colorScheme={"primary"}
              variant={"solid"}
              onClick={() => signInWithGoogle()}
              leftIcon={<RiGoogleLine />}
            >
              Sign in with Google
            </Button>
          </Stack>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={"Login Image"}
          objectFit={"cover"}
          src={splash}
          boxSize="100vh"
        />
      </Flex>
    </Stack>
  );
};
