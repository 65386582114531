import { Center, CircularProgress, Grid } from "@chakra-ui/react";

export const Loader = () => (
  <Grid w="100vw" h="100vh">
    <Center>
      <CircularProgress
        isIndeterminate
        color="primary.500"
        trackColor="secondary.500"
        size="120px"
      />
    </Center>
  </Grid>
);
