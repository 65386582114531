import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import { getFirebaseFunctions } from "@/Firebase";
import { DataContext } from "@/Provider/DataProvider";

interface CreateIssueModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateIssueModal = (props: CreateIssueModalProps) => {
  const { isOpen, onClose } = props;
  const [body, setBody] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const [createIssue, creatingIssue] = useHttpsCallable(
    getFirebaseFunctions(),
    "createIssue"
  );

  const { currentUser } = useContext(DataContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Issue</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <Input
              placeholder="Title"
              onChange={(e) => setTitle(e.target.value)}
            />

            <Textarea
              placeholder="Describe your issue"
              onChange={(e) => setBody(e.target.value)}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={creatingIssue}
            colorScheme="primary"
            onClick={async () => {
              await createIssue({
                title: title,
                body: body,
                userName: currentUser?.name,
                userEmail: currentUser?.email,
                appVersion: import.meta.env.VITE_VERSION || "dev",
              });

              setTitle("");
              setBody("");
              onClose();
            }}
          >
            Create Issue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
