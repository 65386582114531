import "firebase/auth";

import { collection, doc } from "firebase/firestore";
import React, { ReactNode } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";

import { Service } from "@/Common/Service";
import { ServiceTeam } from "@/Common/ServiceTeam";
import { User } from "@/Common/User";
import { RegionMap, Regions } from "@/Data/GCPRegions";
import { getFirebaseAuth, getFirebaseDb } from "@/Firebase";

interface Props {
  children: ReactNode;
}

export interface DataContextInterface {
  regions: RegionMap;
  services: Service[];
  serviceTeams: ServiceTeam[];
  dataClassifications: string[];
  users: string[];
  currentUser: null | User;
}

export const DataContext = React.createContext<DataContextInterface>({
  regions: Regions,
  services: [],
  serviceTeams: [],
  dataClassifications: [],
  users: [],
  currentUser: null,
});

export const DataProvider = (props: Props) => {
  const db = getFirebaseDb();

  const [user] = useAuthState(getFirebaseAuth());

  const serviceTeamsQuery = collection(
    db,
    "/recharger/service_teams/service_teams"
  );
  const servicesQuery = collection(db, "/recharger/services/services");
  const dataClassificationsQuery = collection(db, "data_classification");
  const usersQuery = collection(db, "users");

  const [rawDataClassifications] = useCollection(dataClassificationsQuery);
  const [rawServiceTeams] = useCollection(serviceTeamsQuery);
  const [rawServices] = useCollection(servicesQuery);
  const [rawUsers] = useCollection(usersQuery);
  const [rawCurrentUser] = useDocumentData(doc(db, "users", user?.uid || "0"));

  if (rawCurrentUser) {
    rawCurrentUser.id = user?.uid;
    rawCurrentUser.firebaseUser = user;
  }

  const allServiceTeams: ServiceTeam[] = rawServiceTeams
    ? rawServiceTeams.docs.map((doc): ServiceTeam => doc.data() as ServiceTeam)
    : [];
  allServiceTeams.sort((a: ServiceTeam, b: ServiceTeam) =>
    a.team_name > b.team_name ? 1 : b.team_name > a.team_name ? -1 : 0
  );

  const userServiceTeams: ServiceTeam[] = rawCurrentUser
    ? allServiceTeams.filter((team: ServiceTeam) =>
        (rawCurrentUser as User).serviceTeams.includes(team.id)
      )
    : [];
  userServiceTeams.sort((a: ServiceTeam, b: ServiceTeam) =>
    a.team_name > b.team_name ? 1 : b.team_name > a.team_name ? -1 : 0
  );

  const services: Service[] = rawServices
    ? rawServices.docs.map((doc): Service => doc.data() as Service)
    : [];

  services.sort((a: Service, b: Service) =>
    a.service_name > b.service_name
      ? 1
      : b.service_name > a.service_name
      ? -1
      : 0
  );

  const dataClassifications =
    (rawDataClassifications &&
      rawDataClassifications.docs.map((doc) => doc.data().display_name)) ||
    [];

  const users =
    (rawUsers && rawUsers.docs.map((doc) => doc.data().email)) || [];

  const dataContext: DataContextInterface = {
    regions: Regions,
    services: services,
    serviceTeams:
      rawCurrentUser && rawCurrentUser.admin
        ? allServiceTeams
        : userServiceTeams,
    dataClassifications: dataClassifications,
    users: users,
    currentUser: (rawCurrentUser as User) ?? null,
  };

  return (
    <DataContext.Provider value={dataContext}>
      {props.children}
    </DataContext.Provider>
  );
};
