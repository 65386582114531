import { extendTheme, Theme, theme } from "@chakra-ui/react";

const defaultPink = theme.colors.pink;
const defaultPurple = theme.colors.purple;

export const getTheme = (): Theme => {
  const setUnicorn = localStorage.getItem("unicorn");
  if (setUnicorn === "unicorn") {
    return extendTheme({
      colors: {
        red: Math.random() < 0.5 ? defaultPink : defaultPurple,
        orange: Math.random() < 0.5 ? defaultPink : defaultPurple,
        yellow: Math.random() < 0.5 ? defaultPink : defaultPurple,
        green: Math.random() < 0.5 ? defaultPink : defaultPurple,
        teal: Math.random() < 0.5 ? defaultPink : defaultPurple,
        cyan: Math.random() < 0.5 ? defaultPink : defaultPurple,
        blue: Math.random() < 0.5 ? defaultPink : defaultPurple,
        purple: Math.random() < 0.5 ? defaultPink : defaultPurple,
        primary: Math.random() < 0.5 ? defaultPink : defaultPurple,
        secondary: Math.random() < 0.5 ? defaultPink : defaultPurple,
        tertiary: Math.random() < 0.5 ? defaultPink : defaultPurple,
        tertiary_two: Math.random() < 0.5 ? defaultPink : defaultPurple,
      },
    }) as Theme;
  }

  return extendTheme({
    colors: {
      primary: {
        50: "#ffeee0",
        100: "#f9d2b7",
        200: "#f1b48e",
        300: "#e99862",
        400: "#e27b37",
        500: "#c8611d",
        600: "#9d4b16",
        700: "#70350e",
        800: "#451f04",
        900: "#1d0800",
      },
      secondary: {
        50: "#e1f8ff",
        100: "#bbe6f4",
        200: "#95d4ea",
        300: "#6dc3e0",
        400: "#47b1d7",
        500: "#3198be",
        600: "#237694",
        700: "#15546a",
        800: "#053342",
        900: "#001319",
      },
      tertiary: {
        50: "#dcfdfd",
        100: "#b9f2f3",
        200: "#92e8e8",
        300: "#6bddde",
        400: "#44d3d4",
        500: "#2ababb",
        600: "#1a9191",
        700: "#0b6869",
        800: "#003f3f",
        900: "#001717",
      },
      tertiary_two: {
        50: "#fff8dd",
        100: "#fbebb3",
        200: "#f7de87",
        300: "#f4d158",
        400: "#f0c42b",
        500: "#d7ab13",
        600: "#a7850b",
        700: "#775f05",
        800: "#483900",
        900: "#1b1300",
      },
    },
  }) as Theme;
};
