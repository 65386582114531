import {
  Avatar,
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, useContext } from "react";
import { useSignOut } from "react-firebase-hooks/auth";
import { IconType } from "react-icons";
import { GiUnicorn } from "react-icons/gi";
import {
  RiAdminLine,
  RiArrowDownSLine,
  RiArrowDropRightLine,
  RiBriefcase2Line,
  RiEyeOffLine,
  RiMenuLine,
  RiRecycleLine,
} from "react-icons/ri";
import {
  Link as RouterLink,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { ColorModeSwitcher } from "@/Components/ColorModeSwitcher";
import { Notifications } from "@/Components/Notifications";
import { getFirebaseAuth } from "@/Firebase";
import { DataContext } from "@/Provider/DataProvider";

import logo from "../../logo.svg";

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
}

const HomepageLink = "/products";

const SidebarLinkItems: Array<LinkItemProps> = [
  { name: "Products", icon: RiBriefcase2Line, link: "/products" },
  { name: "Sandboxes", icon: RiRecycleLine, link: "/sandboxes" },
];

interface AvatarMenuLinkItemProps {
  name: string;
  onClick?: (navigate: NavigateFunction) => void;
  adminOnly: boolean;
}

const AvatarMenuItems: Array<AvatarMenuLinkItemProps> = [
  // {
  //   name: "Settings",
  //   onClick: (navigate: NavigateFunction) => {
  //     navigate("/settings");
  //   },
  //   adminOnly: false,
  // },
  {
    name: "Users",
    onClick: (navigate: NavigateFunction) => {
      navigate("/users");
    },
    adminOnly: true,
  },
];

export default function SidebarWithHeader({
  breadcrumbItems,
  children,
}: {
  breadcrumbItems: BreadcrumbItem[];
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Nav onOpen={onOpen} breadcrumbItems={breadcrumbItems} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const bottomHeight = "2em";
  return (
    <>
      <Box
        transition="3s ease"
        bg={useColorModeValue("white", "gray.900")}
        borderRight="1px"
        borderRightColor={useColorModeValue("gray.200", "gray.700")}
        w={{ base: "full", md: 60 }}
        pos="fixed"
        h={{ base: "100%", md: `calc(100% - ${bottomHeight})` }}
        {...rest}
      >
        <Flex
          h="20"
          alignItems="center"
          mx="4"
          my="4"
          justifyContent="space-between"
        >
          <RouterLink to={HomepageLink}>
            <img src={logo} alt="Logo" />
          </RouterLink>

          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </Flex>
        {SidebarLinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} link={link.link}>
            {link.name}
          </NavItem>
        ))}
      </Box>
      <Box
        transition="3s ease"
        bg={useColorModeValue("white", "gray.900")}
        borderRight="1px"
        borderRightColor={useColorModeValue("gray.200", "gray.700")}
        w={{ base: "full", md: 60 }}
        pos="fixed"
        top={{ base: 0, md: `calc(100% - ${bottomHeight})` }}
        h={{ base: 0, md: bottomHeight }}
        textAlign="center"
        display={{ base: "none", md: "block" }}
      >
        <HStack spacing={5} justify="center">
          <Tooltip label="Made with &hearts; by Unicorns" placement="top">
            <span>
              <Icon
                onClick={() => {
                  const setUnicorn = localStorage.getItem("unicorn");
                  if (setUnicorn === "unicorn") {
                    localStorage.removeItem("unicorn");
                  } else {
                    localStorage.setItem("unicorn", "unicorn");
                  }
                  window.location.reload();
                }}
                as={GiUnicorn}
                color="pink.300"
              />
            </span>
          </Tooltip>
          <Text>{import.meta.env.VITE_VERSION || "dev"}</Text>
        </HStack>
      </Box>
    </>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  link: string;
  children: string | number;
}

const NavItem = ({ icon, link, children, ...rest }: NavItemProps) => {
  const location = useLocation();

  const isCurrentPage = location.pathname.startsWith(link);

  return (
    <RouterLink to={link} style={{ textDecoration: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={isCurrentPage ? "primary.500" : "inherit"}
        fontWeight={isCurrentPage ? "bold" : "inherit"}
        _hover={{
          bg: "primary.800",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </RouterLink>
  );
};

export interface BreadcrumbItem {
  title: string;
  link?: string;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
  breadcrumbItems: BreadcrumbItem[];
}

const Nav = ({ onOpen, breadcrumbItems, ...rest }: MobileProps) => {
  const { currentUser } = useContext(DataContext);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<RiMenuLine />}
      />

      <Breadcrumb
        fontSize="4xl"
        fontFamily="monospace"
        fontWeight="bold"
        ml={{ base: 2, md: 0 }}
        separator={<Icon as={RiArrowDropRightLine} mt={5} color="gray.500" />}
      >
        {breadcrumbItems.map((breadcrumbItem, index) => (
          <BreadcrumbItem
            isCurrentPage={index + 1 === breadcrumbItems.length}
            key={index}
            fontSize={index > 0 ? "3xl" : "4xl"}
          >
            <BreadcrumbLink as={RouterLink} to={breadcrumbItem.link || "#"}>
              {breadcrumbItem.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>

      <Spacer />
      <HStack spacing={{ base: 2, md: 6 }}>
        <ColorModeSwitcher />

        <Notifications />

        {currentUser && currentUser.admin && (
          <Tooltip label="Admin User">
            <IconButton
              size="md"
              fontSize="lg"
              variant="ghost"
              color="current"
              marginLeft="2"
              icon={<Icon as={RiAdminLine} boxSize="1.25em" />}
              aria-label="Admin user"
            />
          </Tooltip>
        )}
        {currentUser && currentUser.readOnly && (
          <Tooltip label="Read Only User">
            <IconButton
              size="md"
              fontSize="lg"
              variant="ghost"
              color="current"
              marginLeft="2"
              icon={<Icon as={RiEyeOffLine} boxSize="1.25em" />}
              aria-label="Read Only user"
            />
          </Tooltip>
        )}
        <Flex alignItems={"center"}>
          <AvatarMenu />
        </Flex>
      </HStack>
    </Flex>
  );
};

const AvatarMenu = () => {
  const navigate = useNavigate();

  const auth = getFirebaseAuth();

  const [signOut, ,] = useSignOut(auth);
  const { currentUser } = useContext(DataContext);

  const menuBg = useColorModeValue("white", "gray.800");
  const menuHover = useColorModeValue("gray.200", "gray.600");

  return (
    <Menu>
      <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
        <HStack>
          <Avatar
            size={"sm"}
            name={
              (currentUser &&
                currentUser.firebaseUser &&
                currentUser.firebaseUser.displayName) ||
              ""
            }
            src={(currentUser && currentUser.photoURL) || ""}
          />
          <VStack
            display={{ base: "none", md: "flex" }}
            alignItems="flex-start"
            spacing="1px"
            ml="2"
          >
            <Text fontSize="sm">
              {(currentUser &&
                currentUser.firebaseUser &&
                currentUser.firebaseUser.displayName) ||
                ""}
            </Text>
          </VStack>
          <Box display={{ base: "none", md: "flex" }}>
            <RiArrowDownSLine />
          </Box>
        </HStack>
      </MenuButton>
      <MenuList
        bg={menuBg}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        {AvatarMenuItems.map((item, index) => {
          if (item.name === "---") {
            return <MenuDivider key={index} />;
          }

          if (item.adminOnly && !(currentUser && currentUser.admin)) {
            return null;
          }

          return (
            <MenuItem
              key={item.name}
              onClick={() => (item.onClick ? item.onClick(navigate) : null)}
              bg={menuBg}
              _hover={{ bg: menuHover }}
            >
              {item.name}
            </MenuItem>
          );
        })}
        <MenuDivider />
        <MenuItem
          onClick={() => {
            window.dispatchEvent(new Event("createIssue"));
          }}
          bg={menuBg}
          _hover={{ bg: menuHover }}
        >
          Create Issue
        </MenuItem>

        <MenuDivider />
        <MenuItem
          onClick={async () => {
            await signOut();
          }}
          bg={menuBg}
          _hover={{ bg: menuHover }}
        >
          Sign Out
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
