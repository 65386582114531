import { useDisclosure, useToast } from "@chakra-ui/react";
import { logEvent, setUserId } from "@firebase/analytics";
import { MessagePayload } from "@firebase/messaging";
import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Navigate,
  Outlet,
  useLocation,
  useOutlet,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { CreateIssueModal } from "@/Components/Common/Modals/CreateIssueModal";
import { getFirebaseAnalytics, registerNotifications } from "@/Firebase";
import SidebarWithHeader, {
  BreadcrumbItem,
} from "@/Pages/Layout/SidebarWithHeader";
import { DataContext } from "@/Provider/DataProvider";

export function useSetTitle() {
  return useOutletContext<(breadcrumbItems: BreadcrumbItem[]) => void>();
}

export const useFirebaseRoutesAnalytics = () => {
  const location = useLocation();
  const params = useParams();
  let pathname = location.pathname;

  const flippedParams = Object.fromEntries(
    Object.entries(params).map((a) => a.reverse())
  );

  Object.keys(flippedParams).forEach((value) => {
    pathname = pathname.replace(`/${value}`, `/:${flippedParams[value]}`);
  });

  useEffect(() => {
    const analytics = getFirebaseAnalytics();
    logEvent(analytics, "screen_view", {
      firebase_screen: pathname,
      firebase_screen_class: "routes",
    });
  }, [location]);

  return null;
};

export const Root = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);
  const { currentUser } = useContext(DataContext);
  const toast = useToast();
  useFirebaseRoutesAnalytics();

  const {
    isOpen: isCreateIssueOpen,
    onOpen: onOpenCreateIssue,
    onClose: onCloseCreateIssue,
  } = useDisclosure();

  useEffect(() => {
    window.addEventListener("createIssue", onOpenCreateIssue, false);
  }, []);

  if (currentUser && currentUser.email !== "") {
    setUserId(getFirebaseAnalytics(), currentUser.email);
  }

  useEffect(() => {
    registerNotifications(currentUser, (payload: MessagePayload) => {
      toast({
        title: payload.data?.title,

        description: payload.data?.body,
        status:
          (payload.data?.status as
            | "success"
            | "error"
            | "warning"
            | "info"
            | undefined) || "success",
        duration: 9000,
        isClosable: true,
        variant: "left-accent",
        position: "top",
      });
    });
  }, [currentUser, toast]);

  const title = useMemo(() => {
    if (breadcrumbItems.length === 0) {
      return "Crucible";
    }

    return `Crucible | ${breadcrumbItems
      .map((item) => item.title)
      .join(" > ")}`;
  }, [breadcrumbItems]);

  const outlet = useOutlet();
  if (outlet !== null) {
    return (
      <SidebarWithHeader breadcrumbItems={breadcrumbItems}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Outlet
          context={(newBreadcrumbItems: BreadcrumbItem[]) => {
            if (
              JSON.stringify(breadcrumbItems) !==
              JSON.stringify(newBreadcrumbItems)
            ) {
              setBreadcrumbItems(newBreadcrumbItems);
            }
          }}
        />
        <CreateIssueModal
          isOpen={isCreateIssueOpen}
          onClose={onCloseCreateIssue}
        />
      </SidebarWithHeader>
    );
  }

  return <Navigate to="/products" replace={true} />;
};
