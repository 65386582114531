export interface RegionMap {
  [id: string]: string;
}

export const Regions: RegionMap = {
  "asia-east1": "Changhua County, Taiwan",
  "asia-east2": "Hong Kong",
  "asia-northeast1": "Tokyo, Japan",
  "asia-northeast2": "Osaka, Japan",
  "asia-northeast3": "Seoul, South Korea",
  "asia-south1": "Mumbai, India",
  "asia-southeast1": "Jurong West, Singapore",
  "asia-southeast2": "Jakarta, Indonesia",
  "australia-southeast1": "Sydney, Australia",
  "europe-north1": "Hamina, Finland",
  "europe-west1": "St. Ghislain, Belgium",
  "europe-west2": "London, England",
  "europe-west3": "Frankfurt, Germany",
  "europe-west4": "Eemshaven, Netherlands",
  "europe-west6": "Zurich, Switzerland",
  "northamerica-northeast1": "Montréal, Québec",
  "southamerica-east1": "Osasco, São Paulo, Brazil",
  "us-central1": "Council Bluffs, Iowa",
  "us-east1": "Moncks Corner, South Carolina",
  "us-east4": "Ashburn, Virginia",
  "us-west1": "The Dalles, Oregon",
  "us-west2": "Los Angeles, California",
  "us-west3": "Salt Lake City, Utah",
  "us-west4": "Las Vegas, Nevada",
};
