import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { doc, Firestore, getFirestore, updateDoc } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
} from "firebase/functions";
import {
  getMessaging,
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
} from "firebase/messaging";

import { User } from "@/Common/User";

type Config = {
  firebaseConfig: { [key: string]: string };
  messagingKey: string;
};

const devConfig: Config = {
  firebaseConfig: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: "gcp-project-creator-dev-168908.firebaseapp.com",
    projectId: "gcp-project-creator-dev-168908",
    storageBucket: "gcp-project-creator-dev-168908.appspot.com",
    messagingSenderId: "547949643690",
    appId: "1:547949643690:web:2a6ac620019587db040dd3",
    measurementId: "G-1VC571KEH5",
  },
  messagingKey:
    "BCIWucb5hoWlP12A_Pq38DR0fosSSsataTfHgaS7h1bMUvRidZx4fLhPNvbZxZan4E_unDgxWSnvUI9lyXsw4tQ",
};

const prodConfig: Config = {
  firebaseConfig: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: "gcp-project-creator-168908.firebaseapp.com",
    projectId: "gcp-project-creator-168908",
    storageBucket: "gcp-project-creator-168908.appspot.com",
    messagingSenderId: "55991652767",
    appId: "1:55991652767:web:925bd1272256b86a502daa",
    measurementId: "G-C8MR04X10M",
  },

  messagingKey:
    "BHBRaewrSdJQbQ28fAlW7WVUDZ4V7xFrmpmE8lpYy5_Lc_WsssEatoO9A82pqofRX1V28ohLWWhjsRv3RXhPl-w",
};

const getFirebaseConfig = (): Config => {
  const envName = import.meta.env.VITE_FIREBASE_ENV;

  if (!envName) {
    throw new Error(`Env name not specified. Please set VITE_FIREBASE_ENV`);
  }

  if (envName === "dev") {
    return devConfig;
  }
  return prodConfig;
};

export const getFirebaseApp = (): FirebaseApp => {
  return initializeApp(getFirebaseConfig().firebaseConfig);
};

export const getFirebaseDb = (): Firestore => {
  return getFirestore(getFirebaseApp());
};

export const getFirebaseAuth = (): Auth => {
  return getAuth(getFirebaseApp());
};

export const getFirebaseFunctions = (): Functions => {
  const functions = getFunctions(getFirebaseApp(), "europe-west2");

  if (
    import.meta.env.DEV &&
    import.meta.env.VITE_FIREBASE_FUNC_EMULATOR === "true"
  ) {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }

  return functions;
};

export const getFirebaseMessaging = (): Messaging => {
  return getMessaging(getFirebaseApp());
};

export const getFirebaseAnalytics = (): Analytics => {
  return getAnalytics(getFirebaseApp());
};

let _registration: ServiceWorkerRegistration | undefined = undefined;
const getServiceWorkerRegistration = async () => {
  const config = JSON.stringify(getFirebaseConfig().firebaseConfig);

  if (_registration === undefined) {
    _registration = await navigator.serviceWorker.getRegistration(
      `/firebase-messaging.js?firebaseConfig=${btoa(config)}`
    );

    if (_registration === undefined) {
      _registration = await navigator.serviceWorker.register(
        `/firebase-messaging.js?firebaseConfig=${btoa(config)}`,
        {
          scope: "/firebase-cloud-messaging-push-scope",
        }
      );
    }
  }

  if (_registration !== undefined) {
    _registration.update().catch(() => {
      /* it is non blocking and we don't care if it failed */
    });
  }

  return _registration;
};

export const registerNotifications = async (
  currentUser: null | User,
  onNotification: (payload: MessagePayload) => void
) => {
  if (currentUser === null) {
    return;
  }
  try {
    const currentToken = await getToken(getFirebaseMessaging(), {
      vapidKey: getFirebaseConfig().messagingKey,
      serviceWorkerRegistration: await getServiceWorkerRegistration(),
    });

    if (!currentToken) {
      return;
    }

    // Make sure we save the token
    const userRef = doc(getFirebaseDb(), "users", currentUser.id);
    await updateDoc(userRef, {
      messagingToken: currentToken,
    });

    onMessage(getFirebaseMessaging(), (payload: MessagePayload) => {
      onNotification(payload);
    });
  } catch (error) {
    console.error("An error occurred while retrieving token. ", error);
  }
};
