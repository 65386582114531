import {
  Avatar,
  Box,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Indicator,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { format, formatDistanceToNow } from "date-fns";
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useContext, useRef } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
  RiCheckDoubleLine,
  RiCheckLine,
  RiCloseLine,
  RiNotification2Line,
} from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";

import { Notification } from "@/Common/Config";
import { getFirebaseDb } from "@/Firebase";
import { DataContext } from "@/Provider/DataProvider";

export const Notifications = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  const { currentUser } = useContext(DataContext);

  const db = getFirebaseDb();

  const q = currentUser
    ? query(
        collection(db, `users/${currentUser.id}/notifications`),
        where("hidden", "==", false),
        orderBy("created", "desc"),
        limit(50)
      )
    : null;

  const [notifications, notificationsLoading, notificationsError] =
    useCollection(q);

  notificationsError && console.error(notificationsError);
  const unreadNotifications = notifications
    ? notifications.docs.filter(
        (notification) => !(notification.data() as Notification).read
      )
    : [];

  const setRead = async (
    notificationId: string,
    read: boolean
  ): Promise<void> => {
    if (!currentUser) {
      return;
    }
    const notificationDoc = doc(
      db,
      `users/${currentUser.id}/notifications`,
      notificationId
    );

    await updateDoc(notificationDoc, {
      read: read,
    });
  };

  const hide = async (notificationId: string): Promise<void> => {
    if (!currentUser) {
      return;
    }
    const notificationDoc = doc(
      db,
      `users/${currentUser.id}/notifications`,
      notificationId
    );

    await updateDoc(notificationDoc, {
      hidden: true,
    });
  };

  const markRead = async (notificationId: string): Promise<void> => {
    setRead(notificationId, true);
  };

  const markUnread = async (notificationId: string): Promise<void> => {
    setRead(notificationId, false);
  };

  const markAllRead = () => {
    unreadNotifications.forEach((notification) => {
      markRead(notification.id);
    });
  };

  return (
    <>
      <Tooltip label="Notifications">
        <IconButton
          ref={btnRef}
          onClick={onOpen}
          size="md"
          fontSize="lg"
          variant="ghost"
          color="current"
          marginLeft="2"
          icon={
            <>
              <Avatar
                icon={
                  notificationsLoading ? <Spinner /> : <RiNotification2Line />
                }
                bg="none"
                color="current"
              >
                {unreadNotifications.length > 0 ? (
                  <Indicator placement="bottom-end">
                    <Circle size="5" bg="red.500" fontSize="xs" lineHeight={3}>
                      {unreadNotifications.length}
                    </Circle>
                  </Indicator>
                ) : null}
              </Avatar>
            </>
          }
          aria-label="Notifications"
        />
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            Notifications
            <Tooltip label="Mark all read">
              <IconButton
                ml={2}
                icon={<RiCheckDoubleLine />}
                aria-label="Mark all read"
                variant="ghost"
                size="m"
                onClick={() => markAllRead()}
              />
            </Tooltip>
          </DrawerHeader>

          <DrawerBody>
            {notifications?.docs.map((notification) => {
              const n: Notification = notification.data() as Notification;

              const statusColorMap = {
                success: "green.500",
                error: "red.500",
                warning: "yellow.500",
                info: "secondary.500",
              };

              return (
                <Box
                  borderLeft="5px solid"
                  borderLeftColor={statusColorMap[n.status || "success"]}
                  borderTop="1px solid"
                  borderTopColor="gray.500"
                  borderRight="1px solid"
                  borderRightColor="gray.500"
                  borderBottom="1px solid"
                  borderBottomColor="gray.500"
                  p={5}
                  my={3}
                  shadow={"xl"}
                  roundedEnd={"lg"}
                  key={notification.id}
                >
                  <Flex alignItems="center">
                    <VStack align="left">
                      <Text size="lg" as="b">
                        {n.link ? (
                          <RouterLink
                            onClick={() => {
                              onClose();
                            }}
                            to={n.link}
                          >
                            {n.title}
                          </RouterLink>
                        ) : (
                          n.title
                        )}
                      </Text>
                      <Text size="s">{n.body}</Text>
                      <Text
                        size="xs"
                        as="i"
                        color="grey.200"
                        title={format(
                          n.created.toDate(),
                          "yyyy-MM-dd HH:mm:ss"
                        )}
                      >
                        {formatDistanceToNow(n.created.toDate(), {
                          addSuffix: true,
                        })}
                      </Text>
                    </VStack>
                    <Spacer />

                    <Tooltip label="Clear">
                      <IconButton
                        ml={2}
                        icon={<RiCloseLine />}
                        aria-label="Clear"
                        variant="ghost"
                        size="m"
                        onClick={() => hide(notification.id)}
                        top="-2.8em"
                        left={n.read ? "1.8em" : "2.8em"}
                      />
                    </Tooltip>

                    {n.read ? (
                      <Tooltip label="Mark unread">
                        <IconButton
                          ml={2}
                          icon={<RiCheckboxBlankCircleLine />}
                          aria-label="Mark unread"
                          variant="ghost"
                          size="m"
                          onClick={() => markUnread(notification.id)}
                        />
                      </Tooltip>
                    ) : (
                      <>
                        <Icon as={RiCheckboxBlankCircleFill} color="red.500" />
                        <Tooltip label="Mark read">
                          <IconButton
                            ml={2}
                            icon={<RiCheckLine />}
                            aria-label="Mark read"
                            variant="ghost"
                            size="m"
                            onClick={() => markRead(notification.id)}
                          />
                        </Tooltip>
                      </>
                    )}
                  </Flex>
                </Box>
              );
            })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
