import { Center, Grid, Heading, Text, VStack } from "@chakra-ui/react";
import { useRouteError } from "react-router";

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Grid minH="100vh" p={3}>
      <Center>
        <VStack>
          <Heading fontSize="6xl">Doh!</Heading>
          {error instanceof Error && (
            <>
              <Text fontSize="3xl">{error.message}</Text>
              <pre color="pink.500">{error.stack}</pre>
            </>
          )}
        </VStack>
      </Center>
    </Grid>
  );
};
