import { Text } from "@chakra-ui/react";
import { lazy, Suspense } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";

import { getFirebaseAuth } from "@/Firebase";
import { ErrorBoundary } from "@/Pages/ErrorBoundary";
import { Loader } from "@/Pages/Loader";
import { Root } from "@/Pages/Root";
import { SignIn } from "@/Pages/SignIn";
import { DataProvider } from "@/Provider/DataProvider";

export const userLoader = ({
  params,
}: LoaderFunctionArgs): Response | NonNullable<unknown> | null => {
  if (params.userId) {
    return params.userId;
  }
  return redirect("/users");
};

export const projectLoader = ({
  params,
}: LoaderFunctionArgs): Response | NonNullable<unknown> | null => {
  if (params.projectId) {
    return params.projectId;
  }
  return redirect("/products");
};

export const sandboxLoader = ({
  params,
}: LoaderFunctionArgs): Response | NonNullable<unknown> | null => {
  if (params.sandboxId) {
    return params.sandboxId;
  }
  return redirect("/sandboxes");
};

export const environmentLoader = ({
  params,
}: LoaderFunctionArgs): Response | NonNullable<unknown> | null => {
  if (params.projectId) {
    return {
      projectId: params.projectId,
      environmentName: params.environmentName,
    };
  }
  return redirect("/products");
};

export const App = () => {
  const auth = getFirebaseAuth();

  const [user, loading, error] = useAuthState(auth);

  if (error) {
    return <Text>{error.message}</Text>;
  }

  if (loading) {
    return <Loader />;
  }

  if (user) {
    const Projects = lazy(() => import("@/Pages/Projects/Projects"));
    const Project = lazy(() => import("@/Pages/Projects/Project"));
    const EditProject = lazy(() => import("@/Pages/Projects/EditProject"));
    const NewProject = lazy(() => import("@/Pages/Projects/NewProject"));
    const Environment = lazy(() => import("@/Pages/Environments/Environment"));

    const NewEnvironment = lazy(
      () => import("@/Pages/Environments/NewEnvironment")
    );
    const ImportEnvironment = lazy(
      () => import("@/Pages/Environments/ImportEnvironment")
    );
    const EditEnvironment = lazy(
      () => import("@/Pages/Environments/EditEnvironment")
    );
    const Sandboxes = lazy(() => import("@/Pages/Sandboxes/Sandboxes"));
    const NewSandbox = lazy(() => import("@/Pages/Sandboxes/NewSandbox"));
    const Users = lazy(() => import("@/Pages/Users/Users"));
    const EditUser = lazy(() => import("@/Pages/Users/EditUser"));
    const EditSandbox = lazy(() => import("@/Pages/Sandboxes/EditSandbox"));

    const router = createBrowserRouter([
      {
        path: "/",
        element: (
          <DataProvider>
            <Root />
          </DataProvider>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "/projects",
            element: <Navigate to="/products" />,
          },
          {
            path: "/projects/:projectId",
            element: <Navigate to="/products" />,
            loader: projectLoader,
          },
          {
            path: "/products",
            element: <Projects />,
          },
          {
            path: "/products/:projectId",
            element: <Project />,
            loader: projectLoader,
          },
          {
            path: "/products/:projectId/edit",
            element: <EditProject />,
            loader: projectLoader,
          },
          {
            path: "/products/new",
            element: <NewProject />,
          },
          {
            path: "/products/:projectId/environments/new",
            element: <NewEnvironment />,
            loader: projectLoader,
          },
          {
            path: "/products/:projectId/environments/import",
            element: <ImportEnvironment />,
            loader: projectLoader,
          },
          {
            path: "/products/:projectId/environments/:environmentName",
            element: <Environment />,
            loader: environmentLoader,
          },
          {
            path: "/products/:projectId/environments/:environmentName/edit",
            element: <EditEnvironment />,
            loader: environmentLoader,
          },
          {
            path: "/sandboxes",
            element: <Sandboxes />,
          },
          {
            path: "/sandboxes/:sandboxId/edit",
            element: <EditSandbox />,
            loader: sandboxLoader,
          },
          {
            path: "/sandboxes/new",
            element: <NewSandbox />,
          },
          {
            path: "/users",
            element: <Users />,
          },
          {
            path: "/users/:userId/edit",
            element: <EditUser />,
            loader: userLoader,
          },
        ],
      },
    ]);

    return (
      <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense>
    );
  }

  return <SignIn />;
};
